import React from "react"
import { Link } from "gatsby"
import Loadable from "@loadable/component"
import ReactPlayer from "react-player"
const ModalVideo = Loadable(() => import("react-modal-video"))
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: false,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  items: 1,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
}

const MainBanner = () => {
  // Popup Video
  const [isOpen, setIsOpen] = React.useState(true)
  const openModal = () => {
    setIsOpen(!isOpen)
  }

  // Owl Carousel
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <React.Fragment>
      {/* If you want to change the video need to update videoID */}
      <ModalVideo
        channel="youtube"
        isOpen={!isOpen}
        videoId="KnLtKqFsiCQ"
        onClose={() => setIsOpen(!isOpen)}
      />

      {display ? (
        <OwlCarousel
          className="home-slides owl-carousel owl-theme"
          {...options}
        >
          <div
            className="main-banner-item item-bg1"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="flex flex-mid-col">
              <div
                className="container"
                style={{
                  paddingLeft: window.innerWidth > 990 ? "80px" : "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="banner-item-content">
                  <span className="sub-title">Welcome</span>
                  <h1>One Click Comment Generation!</h1>
                  <p>
                    One of the most powerful ways to grow on LinkedIn is to
                    comment on other people's posts. But coming up with relevant
                    comments can be hard. So let AI do the hard work for you!
                  </p>
                </div>
              </div>
              <ReactPlayer
                className="reactplayer"
                style={{ marginRight: "80px" }}
                width={window.innerWidth > 1080 ? 1080 : window.innerWidth}
                height={window.innerWidth > 1080 ? 1080 : window.innerWidth}
                loop
                muted
                playing
                url="https://vimeo.com/788969186"
              />
            </div>
          </div>

          <div className="main-banner-item item-bg2">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="contaitner">
                  <div
                    className="banner-item-content"
                    style={{
                      paddingLeft: window.innerWidth > 990 ? "80px" : "0",
                    }}
                  >
                    <span className="sub-title">Welcome</span>
                    <h1>Let AI Boost Your Posts!</h1>
                    <p>
                      There is a way to get the LinkedIn Algorithm to boost your
                      post. Commenting on other people's posts just before you
                      post shows LinkedIn that you're active which boosts your
                      reach. But writing interesting comments is hard
                    </p>
                    <p>So let AI do it for you</p>

                    <div className="btn-box">
                      <Link
                        to="#play-video"
                        onClick={e => {
                          e.preventDefault()
                          openModal()
                        }}
                        className="video-btn popup-youtube"
                      >
                        <i className="flaticon-google-play"></i> Watch Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default MainBanner
