//@ts-check
import React, { useContext } from "react";
import NavbarLIP from "../components/LinkedInPowertools/NavbarLIP";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import AuthWrapper from "../components/AuthWrapper";

import MainBanner from "../components/MachineLearningAISolutions/MainBanner";
import Solutions from "../components/MachineLearningAISolutions/Solutions";
import AboutUs from "../components/MachineLearningAISolutions/AboutUs";
import Services from "../components/MachineLearningAISolutions/Services";
import HowItWork from "../components/MachineLearningAISolutions/HowItWork";

import Testimonials from "../components/MachineLearningAISolutions/Testimonials";

import Pricing from "../components/SEOAgency/Pricing";
import ProjectStartArea from "../components/MachineLearningAISolutions/ProjectStartArea";
import PricingSignedin from "../components/SEOAgency/PricingSignedin";
import { AuthContext } from "../context/AuthContext";

const Home = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <AuthWrapper>
      <Layout>
        <NavbarLIP />
        <MainBanner />

        {
          //<AboutUs />
        }
        <HowItWork />
        {
          //<Services />
          //<RecentProjects />
          //<TeamMember />
        }
        <hr />
        <Testimonials />

        {
          //<OurBlog />
        }
        {isLoggedIn ? <PricingSignedin /> : <Pricing />}
        <ProjectStartArea />
        <Footer />
      </Layout>
    </AuthWrapper>
  );
};

export default Home;
