//@ts-check
import React from "react"

import StarIcon from "../../assets/images/star-icon.png"
import HowItsWork from "../../assets/images/how-its-work.png"
import { Link } from "gatsby"

const HowItWork = () => {
  return (
    <section className="how-its-work-area ptb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={StarIcon} alt="image" />
                How It's Work
              </span>
              <h2>AI Post Boosting Process</h2>
              <p>
                Follow this simple process to maximise the reach of your posts
                and the growth of your account.
              </p>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Comment on other people's post 30 mins before yours</h3>
                  <p>
                    This shows the algorithm that you're active. Letting AI
                    write comments that people will like and reply too will make
                    this way more effective
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Publish Your Post</h3>
                  <p>
                    Because the Algorithm knows that you're active, it shows
                    your post to more people. Connections who have just liked
                    one of your comments are far more likely to be shown the
                    post too!
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Rapid Growth</h3>
                  <p>More Comments => More Post Views => Faster Growth</p>
                </div>
              </div>

              <Link
                to="/profile-authentication"
                activeClassName="active"
                className="default-btn"
                style={{ marginTop: "30px" }}
              >
                <i className="flaticon-right"></i>Get Started
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={HowItsWork} alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWork
