import React from "react"
import { Link } from "gatsby"

import ProjectStart1 from "../../assets/images/project-start1.png"
import CircleShape1 from "../../assets/images/shape/circle-shape1.png"

const ProjectStartArea = () => {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={ProjectStart1} alt="image" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>Got some feedback - We'd love to hear it</h2>
              <p>
                Whether it's a feature request, bug you've found, or just to
                tell us how you're growing it would be awesome to hear from you
              </p>

              <a
                href="https://forms.gle/V7Yco3Txf1H6jCHz6"
                className="default-btn"
                target="_blank"
              >
                <i className="flaticon-web"></i>
                Send Feedback
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={CircleShape1} alt="image" />
      </div>
    </div>
  )
}

export default ProjectStartArea
